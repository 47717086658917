<script setup lang="ts">
    const route = useRoute()

    // When accessing /posts/1, route.params.id will be 1
    console.log("route.name", route.name)
</script>
<template>
    <span>
        I am Header
        <slot />
    </span>
    <nav>
      <ul>
        <li><NuxtLink to="/about">About</NuxtLink></li>
        <li><NuxtLink to="/posts/1">Post 1</NuxtLink></li>
        <li><NuxtLink to="/posts/2">Post 2</NuxtLink></li>
      </ul>
    </nav>
</template>